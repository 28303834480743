import React from "react";

import "./SideMenu.css";
import SideBar1 from "../../assets/images/sidemenu-1.svg";
import SideBar2 from "../../assets/images/sidemenu-2.svg";
import SideBar3 from "../../assets/images/sidemenu-3.svg";
import SideBar4 from "../../assets/images/sidemenu-4.svg";
import "bootstrap/dist/css/bootstrap.min.css";
import { Accordion } from "react-bootstrap";

export default function SideMenu() {
    return (
        <Accordion>
            <Accordion.Item eventKey="0">
                <Accordion.Header className="side__menu-heading">
                    <img src={SideBar1} alt="img-1" className="img-fluid mx-2" />
                    Men Clothing
                </Accordion.Header>
                <Accordion.Body>
                    <ul className="side__menu-item">
                        <li className="mb-3">
                            <a href="\">T-Shirts & Tanks</a>
                        </li>
                        <li className="mb-3">
                            <a href="\">Shirt & Polo</a>
                        </li>
                        <li className="mb-3">
                            <a href="\">Pant & Jeans</a>
                        </li>
                        <li className="mb-3">
                            <a href="\">Shorts, Joggers & Sweats</a>
                        </li>
                        <li className="mb-3">
                            <a href="\">Kurta & Shalwar Kameez</a>
                        </li>
                        <li className="mb-3">
                            <a href="\">Winter Clothing</a>
                        </li>
                    </ul>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
                <Accordion.Header>
                    <img src={SideBar2} alt="img-1" className="img-fluid mx-2" />
                    Women Clothing
                </Accordion.Header>
                <Accordion.Body>
                    <ul className="side__menu-item">
                        <li className="mb-3">
                            <a href="\">T-Shirts & Tanks</a>
                        </li>
                        <li className="mb-3">
                            <a href="\">Shirt & Polo</a>
                        </li>
                        <li className="mb-3">
                            <a href="\">Pant & Jeans</a>
                        </li>
                        <li className="mb-3">
                            <a href="\">Shorts, Joggers & Sweats</a>
                        </li>
                        <li className="mb-3">
                            <a href="\">Kurta & Shalwar Kameez</a>
                        </li>
                        <li className="mb-3">
                            <a href="\">Winter Clothing</a>
                        </li>
                    </ul>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
                <Accordion.Header>
                    <img src={SideBar3} alt="img-1" className="img-fluid mx-2" />
                    Kids Clothing
                </Accordion.Header>
                <Accordion.Body>
                    <ul className="side__menu-item">
                        <li className="mb-3">
                            <a href="\">T-Shirts & Tanks</a>
                        </li>
                        <li className="mb-3">
                            <a href="\">Shirt & Polo</a>
                        </li>
                        <li className="mb-3">
                            <a href="\">Pant & Jeans</a>
                        </li>
                        <li className="mb-3">
                            <a href="\">Shorts, Joggers & Sweats</a>
                        </li>
                        <li className="mb-3">
                            <a href="\">Kurta & Shalwar Kameez</a>
                        </li>
                        <li className="mb-3">
                            <a href="\">Winter Clothing</a>
                        </li>
                    </ul>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
                <Accordion.Header>
                    <img src={SideBar4} alt="img-1" className="img-fluid mx-2" />
                    Babys Clothing
                </Accordion.Header>
                <Accordion.Body>
                    <ul className="side__menu-item">
                        <li className="mb-3">
                            <a href="\">T-Shirts & Tanks</a>
                        </li>
                        <li className="mb-3">
                            <a href="\">Shirt & Polo</a>
                        </li>
                        <li className="mb-3">
                            <a href="\">Pant & Jeans</a>
                        </li>
                        <li className="mb-3">
                            <a href="\">Shorts, Joggers & Sweats</a>
                        </li>
                        <li className="mb-3">
                            <a href="\">Kurta & Shalwar Kameez</a>
                        </li>
                        <li className="mb-3">
                            <a href="\">Winter Clothing</a>
                        </li>
                    </ul>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    );
}
