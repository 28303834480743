import React from "react";
import "./AddToCartPro.css";
import useStore from "../../app/store";
import Product1 from "../../assets/images/product-detail-thumb-1.png";
import DeleteIcon from "../../assets/images/del-icon.svg";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";

export default function AddToCartPro() {
    const { cartArr, removeQuantity } = useStore((state) => ({
        cartArr: state.cartArr,
        removeQuantity: state.removeQuantity,
    }));

    const navigate = useNavigate();
    const checkoutHandler = () => navigate("/checkout");
    const cartHandler = () => navigate("/view-cart");

    const totalPrice = cartArr.reduce((total, cart) => (total += cart.price * cart.quantity), 0);

    return (
        <>
            {cartArr?.map((cart, id) => {
                return (
                    <React.Fragment key={id}>
                        <div className="relative">
                            <div className="add_to_cart__products d-flex align-items-start ">
                                <div className="add_to_cart__products-image ">
                                    <img src={cart.image} alt="" />
                                </div>
                                <div className="add_to_cart__products-content px-2">
                                    <h2>{cart.title}</h2>
                                    <p>
                                        {cart.quantity} x{" "}
                                        <span>PKR-{cart.price * cart.quantity}</span>
                                    </p>
                                </div>
                                <button
                                    className="cart__delBtn px-5"
                                    onClick={(e) => {
                                        removeQuantity(cart.id);
                                    }}
                                >
                                    <img src={DeleteIcon} alt="" />
                                </button>
                                <hr className="mt-0"></hr>
                            </div>
                            <div className="add_to_cart__checkout">
                                <div className="add_to_cart__total-price d-flex justify-content-between align-items-center">
                                    <h2>Subtotal:</h2>
                                    <p>PKR {totalPrice}</p>
                                </div>
                                <div className="add_to_cart__checkout-btn">
                                    <button onClick={checkoutHandler}>Checkout</button>
                                </div>
                                <div className="add_to_cart__view-cart-btn mt-3 mb-4">
                                    <button onClick={cartHandler}>View Cart</button>
                                </div>
                            </div>
                        </div>
                        <hr className="mt-0"></hr>
                    </React.Fragment>
                );
            })}
        </>
    );
}
