import React from "react";
import "./HelpCenter.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Accordion from "react-bootstrap/Accordion";

export default function HelpCenter() {
  return (
    <div className="help__center">
      <div className="help__center-heading mb-3 my-4">
        <h2>Help Center</h2>
      </div>
      <div className="help__center-content">
        <Accordion defaultActiveKey="0" className=" my-3  mb-5">
          <Accordion.Item eventKey="0" className="shadow">
            <Accordion.Header>
              Can I use this Template For Next JS or TypeScript learning?
            </Accordion.Header>
            <Accordion.Body className="pt-0">
              Yes, You can use this Template for your Learning process. The
              coding structure is made easy for our customers.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1" className="mt-2 shadow">
            <Accordion.Header>
              How can I deploy BoroBazar Template?
            </Accordion.Header>
            <Accordion.Body className="pt-0">
              Yes, You can use this Template for your Learning process. The
              coding structure is made easy for our customers.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2" className="mt-2 shadow">
            <Accordion.Header>
              How can I get the customer support?
            </Accordion.Header>
            <Accordion.Body className="pt-0">
              Yes, You can use this Template for your Learning process. The
              coding structure is made easy for our customers.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3" className="mt-2 shadow">
            <Accordion.Header>
              Will you regularly give updates of BoroBazar Template?
            </Accordion.Header>
            <Accordion.Body className="pt-0">
              Yes, You can use this Template for your Learning process. The
              coding structure is made easy for our customers.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4" className="mt-2 shadow">
            <Accordion.Header>How can I get the refund?</Accordion.Header>
            <Accordion.Body className="pt-0">
              Yes, You can use this Template for your Learning process. The
              coding structure is made easy for our customers.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="5" className="mt-2 shadow">
            <Accordion.Header>
              Template installation failed, how to run the template?
            </Accordion.Header>
            <Accordion.Body className="pt-0">
              Yes, You can use this Template for your Learning process. The
              coding structure is made easy for our customers.
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </div>
  );
}
