import React from "react";
import "./ViewCart.css";
import CartProduct1 from "../../assets/images/view-cart-product-1.png";
import DelIcon from "../../assets/images/del-icon.svg";
import Arrow from "../../assets/images/Arrow-right.svg";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col, Form, Table, InputGroup, FormControl, Button } from "react-bootstrap";
import useStore from "../../app/store";
import { useNavigate } from "react-router-dom";

export default function ViewCart() {
    const { cartArr, removeQuantity, addQuantity, addCart } = useStore((state) => ({
        cartArr: state.cartArr,
        removeQuantity: state.removeQuantity,
        addQuantity: state.addQuantity,
        addCart: state.addCart,
    }));

    const navigate = useNavigate();
    const checkoutHandler = () => navigate("/checkout");

    const totalPrice = cartArr.reduce((total, cart) => (total += cart.price * cart.quantity), 0);

    return (
        <Container fluid={+true}>
            <Row>
                <Col md={12} lg={9} sm={12}>
                    <div className="view__cart_right">
                        <div className="view__cart-header d-flex justify-content-start align-items-center mb-3">
                            <span className="view__cart-header-space"></span>
                            <h3 className="view__cart-header-content mx-3">Cart updated</h3>
                        </div>

                        <Table responsive>
                            <thead>
                                <tr className="view__cart_right-main-heading borderless">
                                    <th colSpan={2} className="text-right px-3">
                                        PRODUCT
                                    </th>
                                    <th className="text-left">PRICE</th>
                                    <th className="text-left">QUANTITY</th>
                                    <th className="text-center">SUBTOTAL</th>
                                    <th className="text-center"></th>
                                </tr>
                            </thead>
                            <tbody className="view__cart_right-main-body">
                                {cartArr.map((cart) => {
                                    if (cart.id) {
                                        return (
                                            <tr key={cart.id}>
                                                <td className="image view__cart_right-main-body-product-thumbnail">
                                                    <img src={cart.image} alt="product-1" />
                                                </td>
                                                <td className="px-0 view__cart-heading">
                                                    <h6>{cart.title}</h6>
                                                </td>
                                                <td className="px-0">
                                                    <h4>PKR-{cart.price * cart.quantity}</h4>
                                                </td>
                                                <td className="px-0">
                                                    <div className="detail-extralink py-4 mx-0 px-0">
                                                        <div className="detail-qty border radius d-flex justify-content-center align-items-center">
                                                            <button
                                                                className="qty-down qty-btn text-center"
                                                                onClick={() =>
                                                                    removeQuantity(cart.id)
                                                                }
                                                            >
                                                                <svg
                                                                    width="10"
                                                                    height="2"
                                                                    viewBox="0 0 10 2"
                                                                    fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                    <path
                                                                        d="M9.02953 1.05469H5.02001H1.0105"
                                                                        stroke="black"
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                    />
                                                                </svg>
                                                            </button>
                                                            <span className="qty-val">
                                                                {cart.quantity}
                                                            </span>
                                                            <button
                                                                className="qty-up qty-btn text-center"
                                                                onClick={() => addQuantity(cart.id)}
                                                            >
                                                                <svg
                                                                    width="10"
                                                                    height="10"
                                                                    viewBox="0 0 10 10"
                                                                    fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                    <path
                                                                        d="M5.12182 5.12891H1.1123M5.12182 1.12891V5.12891V1.12891ZM5.12182 5.12891V9.12891V5.12891ZM5.12182 5.12891H9.13134H5.12182Z"
                                                                        stroke="black"
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                    />
                                                                </svg>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="px-0 text-center ">
                                                    <h4>PKR-{cart.price * cart.quantity}</h4>
                                                </td>
                                                <td className="px-0 del-icon">
                                                    <button
                                                        className="qty-down"
                                                        onClick={() => removeQuantity(cart.id)}
                                                    >
                                                        <img src={DelIcon} alt="del" />
                                                    </button>
                                                </td>
                                            </tr>
                                        );
                                    } else {
                                        return "Item Not Found";
                                    }
                                })}
                            </tbody>
                        </Table>
                        <div className="d-flex flex-md-row justify-content-between align-items-center d-flex flex-column">
                            <div className="d-flex justify-content-between align-items-center px-2 mt-2">
                                <InputGroup className="mb-3">
                                    <FormControl
                                        placeholder="Coupon Code"
                                        aria-label="Recipient's username"
                                        aria-describedby="basic-addon2"
                                    />
                                    <Button
                                        variant="outline-secondary arrow-icon"
                                        id="button-addon2"
                                    >
                                        <img src={Arrow} alt="" />
                                    </Button>
                                </InputGroup>
                            </div>
                            {/* <div className="update-cart-btn mt-2 mb-2">
                                <button>Update Cart</button>
                            </div> */}
                        </div>
                    </div>
                </Col>
                <Col md={12} lg={3} sm={12} className="pt-5 mt-2">
                    <div className="view__cart_left">
                        <h2 className="mx-3 mb-4 my-4">CART TOTALS</h2>

                        <div className="d-flex justify-content-between align-items-center mx-3 view__cart_left-border-bottom">
                            <p>Subtotal</p>
                            <p>PKR-{totalPrice}</p>
                        </div>
                        <div className="d-flex justify-content-between align-items-center mx-3 my-3">
                            <p>Total</p>
                            <h2>PKR-{totalPrice}</h2>
                        </div>
                        <hr></hr>
                        <div className="view__cart_left-customer-amount mx-3 mb-4 my-4">
                            <h2>Customer Amount</h2>
                            <Form>
                                <Form.Group className="mb-3">
                                    <Form.Label>
                                        Enter Final customer Amount (Inclueing Margin)
                                    </Form.Label>
                                    <Form.Control className="bg-light" type="text" />
                                </Form.Group>
                            </Form>
                        </div>
                        <div className="view__cart_left-btn text-center mb-3">
                            <button onClick={checkoutHandler}>Proceed to checkout</button>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}
