import React from "react";
import "./OrderList.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Form, FormControl, Table, Pagination } from "react-bootstrap";
import SearchGrey from "../../assets/images/search-grey.svg";
import Dots from "../../assets/images/dots.svg";

export default function OrderList() {
  return (
    <div className="order__list">
      <div className="order__list-header d-flex justify-content-between align-items-center mb-5 my-4">
        <div>
          <h2>My order list</h2>
        </div>
        <div className="search-bar">
          <Form>
            <FormControl
              type="text"
              placeholder="Search Order List"
              className="me-2 relative"
            />
            <img src={SearchGrey} className="px-2" alt="search-icon" />
          </Form>
        </div>
      </div>
      <div className="order__list-table">
        <Table borderless responsive hover>
          <thead>
            <tr className="order__list-table-heading">
              <th>Order Number</th>
              <th>Order Date</th>
              <th>Status</th>
              <th>Delivery Time</th>
              <th>Total Price</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr className="table-border-bottom order__list-table-detail">
              <td>8631</td>
              <td>a year ago</td>
              <td>
                <span className="delivered">
                  <span
                    className="bullet"
                    style={{ backgroundColor: "rgb(2, 178, 144)" }}
                  ></span>
                  Delivered
                </span>
              </td>
              <td>25 May, 2021</td>
              <td>867.00</td>
              <td>
                <img src={Dots} alt="" />
              </td>
            </tr>
            <tr className="table-border-bottom order__list-table-detail">
              <td>8632</td>
              <td>a year ago</td>
              <td>
                <span className="delivered">
                  <span
                    className="bullet"
                    style={{ backgroundColor: "rgb(255 235 0)"}}
                  ></span>
                  On the way
                </span>
              </td>
              <td>18 Mar, 2021</td>
              <td>867.00</td>
              <td>
                <img src={Dots} alt="" />
              </td>
            </tr>
          </tbody>
        </Table>
        <div className="d-flex justify-content-end my-3">
          <Pagination>
            <Pagination.Prev />
            <Pagination.Item active>{1}</Pagination.Item>
            <Pagination.Item>{2}</Pagination.Item>
            <Pagination.Next />
          </Pagination>
        </div>
      </div>
    </div>
  );
}
