import { React, useState } from "react";
import "./SendOtp.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col, Form, Offcanvas, Button } from "react-bootstrap";
import Register from "../register/Register";
import Logo from "../../assets/images/logo.png";

export default function SendOtp() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <Container>
        <Row>
          <Col lg={12}>
            <Row>
              <Col md={12}>
                <div className="text-center py-3">
                  <p>
                    Login with your mobile number. We'll send you a code to the
                    given mobile number to login into your account
                  </p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={12} className="d-flex justify-content-center align-items-center">
                <div className="otp__send-input">
                  <Form.Group>
                    <Form.Label>Phone Number</Form.Label>
                    <Form.Control type="phone" placeholder="+92" className="phone-input" />
                  </Form.Group>
                </div>
                <div className="otp__send-input-btn">
                  <button>Send OTP</button>
                </div>
              </Col>
            </Row>
            <Row>
              <div className="register__divider">
                <hr ></hr>
                <span className="register__divider-inner">
                  Or
                </span>
              </div>
            </Row>
            <Row className="text-center back-to-login">
              <p onClick={handleShow}>Back to <span>Login</span></p>
            </Row>
          </Col>
        </Row>
      </Container>

      <Offcanvas placement={"end"} show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>   <img src={Logo} alt="" className="mx-5" fluid="true" /></Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Register />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}
