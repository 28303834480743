import { React, useState } from "react";
import "./Header.css";
import Logo from "../../assets/images/logo.png";
import Category1 from "../../assets/images/category-icon-1.svg";
import Category2 from "../../assets/images/category-icon-2.svg";
import Category3 from "../../assets/images/category-icon-3.svg";
import Category4 from "../../assets/images/category-icon-4.svg";
import Category5 from "../../assets/images/category-icon-5.svg";
import Category6 from "../../assets/images/category-icon-6.svg";
import Category7 from "../../assets/images/category-icon-7.svg";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Navbar, Nav, NavDropdown, Offcanvas } from "react-bootstrap";
import SearchBar from "../home_search_bar/SearchBar";
import SendOtp from "../send_otp_screen/SendOtp";
import OtpCode from "../otp_code_screen/OtpCode";
import Register from "../register/Register";
import {useNavigate } from "react-router-dom";
import { Route, Routes, useLocation } from 'react-router-dom';
import { useEffect } from "react";

function Header() {
    const location = useLocation();
    // console.log(location.pathname);

    const [show, setShow] = useState(false);
    const [showNav, setShowNav] = useState(false);

    const [toggleSearch, setToggleSearch] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const navigate = useNavigate();
    const logoHandler = (e) => {
        e.preventDefault();
        navigate("/");
    };

    const categoryHandler = (e) => {
        e.preventDefault();
        navigate("/category");
    };

    const profileHandler = (e) => {
        e.preventDefault();
        navigate("/user-setting");
    };
 
    useEffect(() => {
        if(location.pathname == "/") {
            window.addEventListener("scroll", () => {
                if (window.scrollY > 200) {
                    setShowNav(true);
                    setToggleSearch(true);
                } else {
                    setShowNav(false);
                    setToggleSearch(false);
                }
            });
            return () => {
                window.removeEventListener("scroll", null);
            };    
        }
        
    }, []);

    return (
        <div className={`header__wrapper ${showNav && "fixed-top nav__transition"}`}>
            <Navbar collapseOnSelect id="navbar_top" expand="lg" className="nav_bg fixed-top">
                <Container fluid>
                    <img
                        onClick={logoHandler}
                        src={Logo}
                        alt=""
                        className="px-4 logo"
                        fluid="true"
                    />

                    <NavDropdown
                        style={{ color: "#02b290" }}
                        title="Select Categories"
                        id="basic-nav-dropdown"
                        className="dropdown_header" 
                    >

                        <NavDropdown.Item onClick={categoryHandler} href="/" className="text-dark">
                            <span className="mx-2">
                                <img src={Category1} alt="Cat-1" className="ml-3" />
                            </span>
                            Clothing
                        </NavDropdown.Item>
                        <NavDropdown.Item href="/" onClick={categoryHandler}>
                            <span className="mx-2">
                                <img src={Category2} alt="Cat-1" className="ml-3" />
                            </span>
                            Perfumes
                        </NavDropdown.Item>
                        <NavDropdown.Item href="/" onClick={categoryHandler}>
                            <span className="mx-2">
                                <img src={Category3} alt="Cat-1" className="ml-3" />
                            </span>
                            Jewelries
                        </NavDropdown.Item>
                        {/* <NavDropdown.Divider /> */}
                        <NavDropdown.Item href="/" onClick={categoryHandler}>
                            <span className="mx-2">
                                <img src={Category4} alt="Cat-1" className="ml-3" />
                            </span>
                            Beauty Products
                        </NavDropdown.Item>
                        <NavDropdown.Item href="/" onClick={categoryHandler}>
                            <span className="mx-2">
                                <img src={Category5} alt="Cat-1" className="ml-3" />
                            </span>
                            Home Decor
                        </NavDropdown.Item>
                        <NavDropdown.Item href="/" onClick={categoryHandler}>
                            <span className="mx-2">
                                <img src={Category6} alt="Cat-1" className="ml-3" />
                            </span>
                            Kitchen Supplies
                        </NavDropdown.Item>
                        <NavDropdown.Item href="/" onClick={categoryHandler}>
                            <span className="mx-2">
                                <img src={Category7} alt="Cat-1" className="ml-3" />
                            </span>
                            Grocery Items
                        </NavDropdown.Item>
                    </NavDropdown>
                    {toggleSearch && (
                        <div className="header-search header-search-animated" id="search">
                            <SearchBar />
                        </div>
                    )}

                    {!toggleSearch && location.pathname != '/' && (
                        <div className="header-search" id="search">
                            <SearchBar />
                        </div>
                    )}
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto Navbar justify-content-end flex-grow-1 align-items-center">
                            <Nav.Link
                                onClick={logoHandler}
                                href="/"
                                className="text-dark custom_link"
                            >
                                Home
                            </Nav.Link>
                            {/* <Nav.Link href="/" className="text-dark custom_link">
                                Contact
                            </Nav.Link> */}
                            <Nav.Link
                                onClick={profileHandler}
                                href="/"
                                className="text-dark custom_link"
                            >
                                Profile
                            </Nav.Link>
                            <Nav.Link href="/" className="text-dark nav_link">
                                <button type="button" className="btn-nav-header" style={{"width":"9rem"}}>
                                    Become a Seller
                                </button>
                            </Nav.Link>
                            <Nav.Link href="#" className="text-dark nav_link">
                                <button
                                    type="button"
                                    onClick={handleShow}
                                    className="btn-nav-header"
                                >
                                    Join
                                </button>
                            </Nav.Link>
                        </Nav>
                        {/* <Nav>
                            <Nav.Link href="/">More deets</Nav.Link>
                            <Nav.Link eventKey={2} href="/">
                                Dank memes
                            </Nav.Link>
                        </Nav> */}
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <Offcanvas placement={"end"} show={show} onHide={handleClose}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>
                        <img src={Logo} alt="" className="mx-5" fluid="true" />
                    </Offcanvas.Title>
                    <hr></hr>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <SendOtp />
                    {/* <OtpCode /> */}
                    {/* <Register /> */}
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    );
}

export default Header;
