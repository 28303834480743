import React from "react";
import Header from "../components/header/Header";
import Banner from "../components/home_banner/Banner";
import Footer from "../components/footer/Footer";
import MobileApp from "../components/mobile_app_store/MobileApp";
import HomeCard from "../components/home_page_card/HomeCard";
import Image1 from "../assets/images/home-page-card-2.jpg";
import Image2 from "../assets/images/home-page-card-3.jpg";
import Image3 from "../assets/images/home-page-card-4.jpg";
import CatImage1 from "../assets/images/category-card1.png";
import CatImage2 from "../assets/images/category-card2.png";
import CatImage3 from "../assets/images/category-card3.png";
import CatImage4 from "../assets/images/category-card4.png";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col } from "react-bootstrap";
import CategoriesCard from "../components/categories_home_card/CategoriesCard";
import ProductList from "../components/productList/ProductList";
import product1 from "../assets/images/product1.png";
import product2 from "../assets/images/product2.png";
import product3 from "../assets/images/product3.png";
import product4 from "../assets/images/product4.png";
import product5 from "../assets/images/product5.png";
import product6 from "../assets/images/product6.png";
import product7 from "../assets/images/product7.png";
import product8 from "../assets/images/product8.png";
import product9 from "../assets/images/product9.png";
import product10 from "../assets/images/product10.png";
import product11 from "../assets/images/product11.png";
import product12 from "../assets/images/product12.png";
import CartButton from "../components/cart_button/CartButton";
import CatMen from "../assets/images/cat-men.jpg";
import CatKid from "../assets/images/cat-kid.png";
import CatHair from "../assets/images/cat-hair.png";
import CatAccessories from "../assets/images/cat-accessories.png";

export default function HomePage() {
  const cardObj = [
    {
      id: "1",
      title: "Love Spicy Food Try Pizza Item",
      desc: "Get your fresh food for diet plan and get healthy anytime",
      img: Image1,
    },
    {
      id: "2",
      title: "Amazing Pet food items available",
      desc: "Get your fresh food for diet plan and get healthy anytime",
      img: Image2,
    },
    {
      id: "3",
      title: "Your Perfect Diet Choice to burn",
      desc: "Get your fresh food for diet plan and get healthy anytime",
      img: Image3,
    },
  ];
  const categorycardObj = [
    {
      id: "1",
      title: "Spring cleaning for home appliance",
      desc: "Get your clean on supplies.",
      img: CatImage1,
      bg: "#FFEED6",
    },
    {
      id: "2",
      title: "Your pet choice for fresh healthy food",
      desc: "Get your clean on supplies.",
      img: CatImage2,
      bg: "#D9ECD2",
    },
    {
      id: "3",
      title: "Washing item with discount product",
      desc: "Get your clean on supplies.",
      img: CatImage3,
      bg: "#66C1F9",
    },
    {
      id: "4",
      title: "Fresh quality meat item with discount",
      desc: "Get your clean on supplies.",
      img: CatImage4,
      bg: "#EFD8D4",
    },
  ];

  const productData = [
    {
      id: "1",
      img: product1,
      title: "White and black crew-neck",
      price: 100,
      quantity: 1,
      tag: {
        value: 17,
        bg: "#02B290",
      },
    },
    {
      id: "2",
      img: product2,
      title: "White and black crew-neck",
      price: 200,
      quantity: 1,
      tag: {
        value: 29,
        bg: "#A4000D",
      },
    },
    {
      id: "3",
      img: product3,
      title: "White and black crew-neck",
      price: 300,
      quantity: 1,
      tag: {
        value: 50,
        bg: "#A4000D",
      },
    },
    {
      id: "4",
      img: product4,
      title: "White and black crew-neck",
      price: 400,
      quantity: 1,
      tag: {},
    },
    {
      id: "5",
      img: product5,
      title: "White and black crew-neck",
      price: 400,
      quantity: 1,
      tag: {
        value: 10,
        bg: "#A4000D",
      },
    },
    {
      id: "6",
      img: product6,
      title: "White and black crew-neck",
      price: 400,
      quantity: 1,
      tag: {
        value: 17,
        bg: "#02B290",
      },
    },
    {
      id: "7",
      img: product7,
      title: "White and black crew-neck",
      price: 400,
      quantity: 1,
      tag: {},
    },
    {
      id: "8",
      img: product8,
      title: "White and black crew-neck",
      price: 400,
      quantity: 1,
      tag: {
        value: 17,
        bg: "#A4000D",
      },
    },
    {
      id: "9",
      img: product9,
      title: "White and black crew-neck",
      price: 400,
      quantity: 1,
      tag: {
        value: 17,
        bg: "#A4000D",
      },
    },
    {
      id: "10",
      img: product10,
      title: "White and black crew-neck",
      price: 400,
      quantity: 1,
      tag: {
        value: 17,
        bg: "#A4000D",
      },
    },
    {
      id: "11",
      img: product11,
      title: "White and black crew-neck",
      price: 400,
      quantity: 1,
      tag: {
        value: 17,
        bg: "#A4000D",
      },
    },
    {
      id: "12",
      img: product12,
      title: "White and black crew-neck",
      price: 400,
      quantity: 1,
      tag: {
        value: 17,
        bg: "#A4000D",
      },
    },
  ];
  return (
    <div>
      <CartButton />
      <Header />
      <Banner />
      <Container fluid={+true}>
        {/* This is card category Object */}
        <Row>
          {categorycardObj.map((catcard) => {
            return (
              <Col xs={12} md={3} className="mx-0 px-0" key={catcard.id}>
                <CategoriesCard
                  title={catcard.title}
                  desc={catcard.desc}
                  image={catcard.img}
                  bg={catcard.bg}
                />
              </Col>
            );
          })}
        </Row>

        <Row>
          <Col lg={6}>
            <div className="child1 grid-col-span-2 cat__child text-center pt-3">
              <img src={CatMen} alt="" className="img-fluid" />
              <div className="catBtn-wrap">
                <button
                  type="button"
                  className="home__page_card_btn border btn-lg"
                >
                  Men
                </button>
              </div>
            </div>
          </Col>
          <Col lg={6}>
            <Row className="d-flex justify-content-center align-items-center">
              <Col md={6} lg={6}>
                <div className="child2 cat__child text-center pt-2">
                  <img src={CatKid} alt="" className="img-fluid"/>
                  <div className="catBtn-wrap">
                    <button
                      type="button"
                      className="home__page_card_btn border btn-lg"
                    >
                      Kids
                    </button>
                  </div>
                </div>
              </Col>
              <Col md={6} lg={6}>
                <div className="child3 cat__child text-center pt-2 pb-2">
                  <img src={CatAccessories} alt="" className="img-fluid"/>
                  <div className="catBtn-wrap">
                    <button
                      type="button"
                      className="home__page_card_btn border  btn-lg"
                    >
                      Accessories
                    </button>
                  </div>
                </div>
              </Col>
              <Col lg={12}>
                <div className="child4 grid-col-span-2 cat__child pt-4 text-center">
                  <img src={CatHair} alt="" className="img-fluid"/>
                  <div className="catBtn-wrap">
                    <button
                      type="button"
                      className="home__page_card_btn border btn-lg"
                    >
                      Haircare
                    </button>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>

        {/* <section className="homeCat__section mt-5 mb-3 px-5">
                    <div className="home__cat">
                        <div className="child1 grid-col-span-2 cat__child">
                            <img src={CatMen} alt="" />
                            <div className="catBtn-wrap">
                                <button
                                    type="button"
                                    className="home__page_card_btn btn btn-primary btn-lg"
                                >
                                    Men
                                </button>
                            </div>
                        </div>
                        <div className="child2 cat__child">
                            <img src={CatKid} alt="" />
                            <div className="catBtn-wrap">
                                <button
                                    type="button"
                                    className="home__page_card_btn btn btn-primary btn-lg"
                                >
                                    Kids
                                </button>
                            </div>
                        </div>
                        <div className="child3 cat__child">
                            <img src={CatAccessories} alt="" />
                            <div className="catBtn-wrap">
                                <button
                                    type="button"
                                    className="home__page_card_btn btn btn-primary btn-lg"
                                >
                                    Accessories
                                </button>
                            </div>
                        </div>
                        <div className="child4 grid-col-span-2 cat__child">
                            <img src={CatHair} alt="" />
                            <div className="catBtn-wrap">
                                <button
                                    type="button"
                                    className="home__page_card_btn btn btn-primary btn-lg"
                                >
                                    Haircare
                                </button>
                            </div>
                        </div>
                    </div>
                </section> */}

        {/* Best seller here */}
        <ProductList
          products={productData}
          heading={"Best seller grocery near you"}
          para={
            "We provide best quality & fresh grocery items near your location"
          }
        />

        {/* This is Home Card Object */}
        <Row>
          {cardObj.map((card) => {
            return (
              <Col xs={12} md={4} className="px-2 mb-2" key={card.id}>
                <HomeCard
                  title={card.title}
                  desc={card.desc}
                  image={card.img}
                />
              </Col>
            );
          })}
        </Row>

        {/* Popular products here */}
        <ProductList
          products={productData}
          heading={"Popular product that we sold"}
          para={
            "We provide best quality & fresh grocery items near your location"
          }
        />
      </Container>
      <MobileApp />
      <Footer />
    </div>
  );
}
