import React from "react";
import "./CartBanner.css";
// import CartBanner1 from "../../assets/images/cart-banner-icon1.png";
// import CartBanner2 from "../../assets/images/cart-banner-icon2.png";
import "bootstrap/dist/css/bootstrap.min.css";

export default function CartBanner() {
  return (
    <div className="cart__banner mb-4 text-center py-5 mt-5">
      {/* <img src={CartBanner1} alt="icon1" className="img-fluid" /> */}
      <h2 className="text-center py-5">Cart</h2>
      <div>
          <p>Home / Cart</p>
      </div>
      {/* <img src={CartBanner2} alt="icon2" className="img-fluid" /> */}
    </div>
  );
}
