import React from "react";
import "./Tag.css";

export default function Tag({ bg, value }) {
    return (
        <div className="tag">
            {value && bg && <p style={{ background: bg }}>-{value}%</p>}
        </div>
    );
}
