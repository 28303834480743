import React from "react";
import "./VendorProfile.css";
import VendorImg from "../../assets/images/vendor-img.png";

export default function VendorProfile() {
  return (
    <div className="vendor__profile">
      <div className="vendor__profile-image text-center">
        <img src={VendorImg} alt="" />
      </div>
      <div className="vendor__profile-info text-center mt-3">
        <h2>Jane Doe</h2>
        <p>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry.
        </p>
        <button type="button">Read more</button>
      </div>
      <hr></hr>
      <div className="vendor__profile-phone text-center">
        <h2 className="mb-0">Phone</h2>
        <p>0345-6062607</p>
      </div>
      <hr></hr>
      <div className="vendor__profile-rating text-center">
        <h3>Positive Seller Ratings</h3>
        <p>75%</p>
      </div>
    </div>
  );
}
