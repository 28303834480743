import React from "react";
import "./SearchBar.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Col, Row, Form } from "react-bootstrap";
import Search from "../../assets/images/search-icon.svg";

export default function SearchBar() {
    return (
        <Col className="search__bar d-flex justify-content-center align-items-center" id="search">
            <Form.Control
                className="searchTerm"
                type="text"
                placeholder="What are you Looking..."
            />
            <div className="search__bar_button d-flex justify-content-center align-items-center">
                <img src={Search} className="px-2" alt="search-icon" />
                <button className="search__bar_button">Search</button>
            </div>
        </Col>
    );
}
