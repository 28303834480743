import React from "react";
import "./ProductFilter.css";
import FilterIcon from "../../assets/images/filter-icon-1.svg";
import "bootstrap/dist/css/bootstrap.min.css";
import { Form } from "react-bootstrap";

export default function ProductFilter() {
    return (
        <div className="filter__header d-flex justify-content-start align-items-center mb-4">
            <div className="mx-3">
                <img src={FilterIcon} alt="" fluid="true" />
            </div>
            <div className="mx-0">
                <Form.Select aria-label="Floating label select example" className="filter__header-dropdown mx-2">
                    <option>Sort by popularity</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                </Form.Select>
            </div>
            <div className="filter__header-content text-center mx-3 pt-3">
                <p>2,650 Items Found</p>
            </div>
        </div>
    );
}
