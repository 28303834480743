import React, { useState } from "react";
import "./UserProfile.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Row, Form, Col } from "react-bootstrap";

export default function UserProfile() {
    const [passwordShown, setPasswordShown] = useState(false);

    const togglePassword = () => {
        setPasswordShown(!passwordShown);
    };
    return (
        <div className="user__profile">
            <div className="user__profile-heading mb-3 my-4">
                <h2>Personal Information</h2>
            </div>
            <div className="user__profile-personal-info">
                <Form>
                    <Row>
                        <Col md={6}>
                            <Form.Group className="mb-3" controlId="formGroupFirstName">
                                <Form.Label>First Name*</Form.Label>
                                <Form.Control type="text" />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3" controlId="formGroupLastName">
                                <Form.Label>Last Name*</Form.Label>
                                <Form.Control type="text" />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3" controlId="formGroupPhone">
                                <Form.Label>Phone/Mobile*</Form.Label>
                                <Form.Control type="text" />
                            </Form.Group>
                        </Col>
                        <hr className="divider mb-4 mt-2"></hr>
                        <Col md={12}>
                            <h2 className="mb-3">Account Information</h2>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3" controlId="formGroupEmail">
                                <Form.Label>Email*</Form.Label>
                                <Form.Control type="email" />
                            </Form.Group>
                        </Col>
                        <Col md={6}></Col>
                        <Col md={6}>
                            <Form.Group className=" mb-3" controlId="formGroupPassword">
                                <Form.Label>Password*</Form.Label>
                                <div className="d-flex">
                                    <Form.Control type={passwordShown ? "text" : "password"} />
                                    <button type="button" onClick={togglePassword} className="show-hide-btn text-center">
                                        <i className="fa fa-eye" aria-hidden="true"></i>
                                    </button>
                                </div>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3" controlId="formGroupConfirmPhone">
                                <Form.Label>Confirm Password*</Form.Label>
                                <div className="d-flex">
                                    <Form.Control type={passwordShown ? "text" : "password"} />
                                    <button type="button" onClick={togglePassword} className="show-hide-btn text-center">
                                        <i className="fa fa-eye" aria-hidden="true"></i>
                                    </button>
                                </div>
                            </Form.Group>
                        </Col>
                        <hr className="divider"></hr>
                        <Col md={12}>
                            <div className="user__profile-personal-info-footer-content d-flex justify-content-between align-items-center my-3 mb-0">
                                <div>
                                    <h3>Share Profile Data</h3>
                                    <p>Share your profile information to collect the product search result</p>
                                </div>
                                <div>
                                    <Form.Check type="switch" id="custom-switch" />
                                </div>
                            </div>
                            <div className="user__profile-personal-info-footer-content d-flex justify-content-between align-items-center">
                                <div>
                                    <h3>Ads Performance</h3>
                                    <p>To improve your ads search result we need to collect your cookies behavior</p>
                                </div>
                                <div>
                                    <Form.Check type="switch" id="custom-switch" />
                                </div>
                            </div>
                        </Col>
                        <Col>
                            <div className="d-flex justify-content-end mt-3 mb-3">
                                <button className="btn-save">Save Changes</button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </div>
        </div>
    );
}
