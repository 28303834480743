import React, { useState } from "react";
import "./CheckOut.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col, Form } from "react-bootstrap";
import OrderSummary from "../order_summary/OrderSummary";

export default function CheckOut() {
    const [inputValues, setInputValues] = useState({});
    const [counter, setCounter] = useState(0);

    const handleClick = () => {
        setCounter(counter + 1);
    };

    const handleOnChange = (e) => {
        const abc = {};
        abc[e.target.className] = e.target.value;
        setInputValues({ ...inputValues, ...abc });
    };
    return (
        <Row>
            <Col lg={8} md={12} xs={12}>
                <Row>
                    <Col md={12} className="main-heading mb-4">
                        <h2>Billing Details</h2>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>First Name</Form.Label>
                            <Form.Control type="text" placeholder="First Name" />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Street Addres </Form.Label>
                            <Form.Control type="text" placeholder="Street Addres" className="mb-2" />
                            {Array.from(Array(counter)).map((c, index) => {
                                return (
                                    <>
                                        <Form.Label>Street Addres</Form.Label>
                                        <Form.Control onChange={handleOnChange} key={c} className={index} type="text" />
                                    </>
                                );
                            })}
                            <Form.Text className="text-dark">
                                <button className="add-field" onClick={handleClick}>
                                    + Add Line 2
                                </button>
                            </Form.Text>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>City</Form.Label>
                            <Form.Control type="text" placeholder="City" />
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Zip Code</Form.Label>
                            <Form.Control type="text" placeholder="Zip Code" />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Phone Number</Form.Label>
                            <Form.Control type="text" placeholder="Phone Number" />
                        </Form.Group>
                        {/* <Form.Group className="mb-3" controlId="formBasicCheckbox">
                <Form.Check type="radio" className="radio-btn" />
              </Form.Group> */}
                        <div className="main-heading mt-2">
                            <h2>Payment Method</h2>
                        </div>
                        <div className="d-flex justify-content-start align-items-center radio-btn mt-3">
                            <Form>
                                {["radio"].map((type) => (
                                    <div key={`inline-${type}`} className="mb-3">
                                        <Form.Check inline label="Credit Card" name="group1" type={type} id={`inline-${type}-1`} />
                                        <Form.Check inline label="Cash on Delivery" name="group1" type={type} id={`inline-${type}-2`} />
                                        <Form.Check inline name="group1" label="Easypaisa" type={type} id={`inline-${type}-3`} />
                                    </div>
                                ))}
                            </Form>
                        </div>
                    </Col>
                </Row>
            </Col>
            <Col lg={4} md={12} xs={12}>
                <OrderSummary />
            </Col>
        </Row>
    );
}
