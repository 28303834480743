import React from "react";
import "./HomeCard.css";
import "bootstrap/dist/css/bootstrap.min.css";

import {
  Button
} from "react-bootstrap";

export default function HomeCard({title,desc,image}) {
  return (
    <div className="home__page_card  d-flex flex-column justify-content-center align-items-start px-5" style={{backgroundImage: "url("+image+")"}}>
      <h2>
      {title}
      </h2>
      <p>{desc}</p>
      <Button className="home__page_card_btn">Explore Food</Button>
    </div>
  );
}
