import React from "react";
import "./UserWishlist.css";
import "bootstrap/dist/css/bootstrap.min.css";
// import { Row, Form, Col } from "react-bootstrap";
import WishList1 from "../../assets/images/wishlist-1.jpg";
import Heart from "../../assets/images/heart.svg";

export default function UserWishlist() {
  return (
    <div className="user__wishlist">
      <div className="user__wishlist-heading mb-5 my-4">
        <h2>Favorite List</h2>
      </div>
      <div className="user__wishlist-content">
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-start">
            <div>
              <img src={WishList1} alt="" height={90}  />
            </div>
            <div className="mx-2">
              <h2>Red crew-neck</h2>
              <h3>1 each</h3>
              <p>PKR-1100</p>
            </div>
          </div>
          <div className="d-flex">
            <img src={Heart} alt="" className="img-fluid"/>
            <h4 className="mx-1">Favorited</h4>
          </div>
        </div>
        <hr></hr>
      </div>
    </div>
  );
}
