import React from "react";
import "./UserAddress.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Row, Form, Col } from "react-bootstrap";

export default function UserAddress() {
  return (
    <div className="user__address">
      <div className="user__address-heading mb-5 my-4">
        <h2>Address Information</h2>
      </div>
      <div className="user__address-content">
        <Form>
          <Row>
            <Col md={12}>
              <Form.Group className="mb-3" controlId="formGroupText">
                <Form.Label>Title</Form.Label>
                <Form.Control type="text" />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="formGroupText">
                <Form.Label>Country</Form.Label>
                <Form.Control type="text" />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="formGroupText">
                <Form.Label>City</Form.Label>
                <Form.Control type="text" />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="formGroupText">
                <Form.Label>State</Form.Label>
                <Form.Control type="text" />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="formGroupText">
                <Form.Label>Zip</Form.Label>
                <Form.Control type="text" />
              </Form.Group>
            </Col>
            <Col md={12}>
              <Form.Group className="mb-3" controlId="formGroupText">
                <Form.Label>State</Form.Label>
                <Form.Control type="text" />
              </Form.Group>
            </Col>
            <Col md={12}>
              <Form.Group className="mb-3" controlId="formGroupText">
                {/* <Form.Label>State</Form.Label> */}
                <Form.Control type="text" />
              </Form.Group>
            </Col>
            <Col>
              <div className="d-flex justify-content-end mt-3 mb-3">
                <button className="btn-save">Save Changes</button>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
}
