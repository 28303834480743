import React, { useState } from "react";
import "./ChangePassword.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Row, Form, Col } from "react-bootstrap";

export default function ChangePassword() {
    const [passwordShown, setPasswordShown] = useState(false);

    const togglePassword = () => {
        setPasswordShown(!passwordShown);
    };
    return (
        <div className="change__password">
            <div className="change__password-heading mb-3 my-4">
                <h2>Change Password</h2>
            </div>
            <div className="change__password-input">
                <Form>
                    <Row>
                        <Col md={8}>
                            <Form.Group className=" mb-3" controlId="formGroupPassword">
                                <Form.Label>Old Password</Form.Label>
                                <div className="d-flex">
                                    <Form.Control type={passwordShown ? "text" : "password"} />
                                    <button type="button" onClick={togglePassword} className="show-hide-btn text-center">
                                        <i className="fa fa-eye" aria-hidden="true"></i>
                                    </button>
                                </div>
                            </Form.Group>
                        </Col>
                        <Col md={8}>
                            <Form.Group className="mb-3" controlId="formGroupConfirmPhone">
                                <Form.Label>New Password</Form.Label>
                                <div className="d-flex">
                                    <Form.Control type={passwordShown ? "text" : "password"} />
                                    <button type="button" onClick={togglePassword} className="show-hide-btn text-center">
                                        <i className="fa fa-eye" aria-hidden="true"></i>
                                    </button>
                                </div>
                            </Form.Group>
                        </Col>
                        <Col md={12}>
                            <div className="mt-3 mb-3">
                                <button className="btn-save">Change Password</button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </div>
        </div>
    );
}
