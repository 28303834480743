import React from "react";
import "./Banner.css";
import "bootstrap/dist/css/bootstrap.min.css";
// import { Container, Col, Row, Form } from "react-bootstrap";
// import Search from "../../assets/images/search-icon.png";
import SearchBar from "../home_search_bar/SearchBar";
export default function Banner() {
    return (
        <div className="home-banner-bg d-flex justify-content-center align-items-center">
            <SearchBar />
        </div>
    );
}
