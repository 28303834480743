import React from "react";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import MobileApp from "../components/mobile_app_store/MobileApp";
import Banner from "../components/home_banner/Banner";
import CategoriesCard from "../components/categories_home_card/CategoriesCard";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col } from "react-bootstrap";
import CatImage1 from "../assets/images/category-card1.png";
import CatImage2 from "../assets/images/category-card2.png";
import CatImage3 from "../assets/images/category-card3.png";
import CatImage4 from "../assets/images/category-card4.png";
import SideMenu from "../components/side_menu_bar/SideMenu";
import ProductFilter from "../components/product_filter/ProductFilter";
import ProductList from "../components/productList/ProductList";
import product1 from "../assets/images/product1.png";
import product2 from "../assets/images/product2.png";
import product3 from "../assets/images/product3.png";
import product4 from "../assets/images/product4.png";
import product5 from "../assets/images/product5.png";
import product6 from "../assets/images/product6.png";
import product7 from "../assets/images/product7.png";
import product8 from "../assets/images/product8.png";
import product9 from "../assets/images/product9.png";
import product10 from "../assets/images/product10.png";
import product11 from "../assets/images/product11.png";
import product12 from "../assets/images/product12.png";
import CartButton from "../components/cart_button/CartButton";

export default function CategoryPage() {
    const categorycardObj = [
        {
            id: "1",
            title: "Spring cleaning for home appliance",
            desc: "Get your clean on supplies.",
            img: CatImage1,
            bg: "#FFEED6",
        },
        {
            id: "2",
            title: "Your pet choice for fresh healthy food",
            desc: "Get your clean on supplies.",
            img: CatImage2,
            bg: "#D9ECD2",
        },
        {
            id: "3",
            title: "Washing item with discount product",
            desc: "Get your clean on supplies.",
            img: CatImage3,
            bg: "#66C1F9",
        },
        {
            id: "4",
            title: "Fresh quality meat item with discount",
            desc: "Get your clean on supplies.",
            img: CatImage4,
            bg: "#EFD8D4",
        },
    ];
    const productData = [
        {
            id: "1",
            img: product1,
            title: "White and black crew-neck",
            price: 400,
            quantity: 1,
            tag: {
                value: 17,
                bg: "#02B290",
            },
        },
        {
            id: "2",
            img: product2,
            title: "White and black crew-neck",
            price: 400,
            quantity: 1,
            tag: {
                value: 29,
                bg: "#A4000D",
            },
        },
        {
            id: "3",
            img: product3,
            title: "White and black crew-neck",
            price: 400,
            quantity: 1,
            tag: {
                value: 50,
                bg: "#A4000D",
            },
        },
        {
            id: "4",
            img: product4,
            title: "White and black crew-neck",
            price: 400,
            quantity: 1,
            tag: {},
        },
        {
            id: "5",
            img: product5,
            title: "White and black crew-neck",
            price: 400,
            quantity: 1,
            tag: {
                value: 10,
                bg: "#A4000D",
            },
        },
        {
            id: "6",
            img: product6,
            title: "White and black crew-neck",
            price: 400,
            quantity: 1,
            tag: {
                value: 17,
                bg: "#02B290",
            },
        },
        {
            id: "7",
            img: product7,
            title: "White and black crew-neck",
            price: 400,
            quantity: 1,
            tag: {},
        },
        {
            id: "8",
            img: product8,
            title: "White and black crew-neck",
            price: 400,
            quantity: 1,
            tag: {
                value: 17,
                bg: "#A4000D",
            },
        },
        {
            id: "9",
            img: product9,
            title: "White and black crew-neck",
            price: 400,
            quantity: 1,
            tag: {
                value: 17,
                bg: "#A4000D",
            },
        },
        {
            id: "10",
            img: product10,
            title: "White and black crew-neck",
            price: 400,
            quantity: 1,
            tag: {
                value: 17,
                bg: "#A4000D",
            },
        },
        {
            id: "11",
            img: product11,
            title: "White and black crew-neck",
            price: 400,
            quantity: 1,
            tag: {
                value: 17,
                bg: "#A4000D",
            },
        },
        {
            id: "12",
            img: product12,
            title: "White and black crew-neck",
            price: 400,
            quantity: 1,
            tag: {
                value: 17,
                bg: "#A4000D",
            },
        },
    ];
    return (
        <>
            <CartButton />
            <Header />
            <Banner />
            <Container fluid={+true} className="px-4">
                {/* This is card category Object */}
                <Row>
                    {categorycardObj.map((catcard) => {
                        return (
                            <Col xs={12} md={3} className="mx-0 px-0" key={catcard.id}>
                                <CategoriesCard title={catcard.title} desc={catcard.desc} image={catcard.img} bg={catcard.bg} />
                            </Col>
                        );
                    })}
                </Row>

                <Row className="mt-5">
                    <Col sm={12} md={12} lg={2}>
                        <SideMenu />
                    </Col>
                    <Col sm={12} md={12} lg={10}>
                        <ProductFilter />
                        <ProductList products={productData} />
                    </Col>
                </Row>
            </Container>
            <MobileApp />
            <Footer />
        </>
    );
}
