import React from "react";
import "./OrderSummary.css";
import "bootstrap/dist/css/bootstrap.min.css";
import CartProduct1 from "../../assets/images/view-cart-product-1.png";
import Cross from "../../assets/images/cross.svg";
import { Form, FormControl } from "react-bootstrap";

export default function OrderSummary() {
    return (
        <div className="order__summary px-3 py-3">
            <div className="order__summary-header">
                <h2>Order Summary</h2>
                <button>
                    <img src={Cross} alt="" />
                </button>
            </div>
            <div className="order__summary-inner-sec d-flex justify-content-left gap-4 px-3 py-3">
                <div className="order__summary-inner-sec-image">
                    <img src={CartProduct1} alt="" />
                </div>
                <div className="order__summary-inner-sec-content">
                    <p>alkaram</p>
                    <h4>Red crew-neck</h4>
                    <h5>PKR-1100</h5>
                </div>
            </div>
            <div className="d-flex justify-content-center align-items-center coupon__code-sec">
                <Form.Control type="text" placeholder="Coupon code" className="coupon__code-input" />
                <button className="mx-2">Add code</button>
            </div>
            <div className="order__summary-inner-sec-amount px-2 py-3">
                <hr></hr>
                <div className="d-flex justify-content-between align-items-center">
                    <p>Subtotal</p>
                    <h2>PKR-2200</h2>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                    <p>Shipping</p>
                    <h3>FREE</h3>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                    <p>Customer Amount</p>
                    <h2>500</h2>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                    <p>Discount</p>
                    <h4>-</h4>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                    <p>Tax</p>
                    <h4>-</h4>
                </div>
                <hr></hr>
                <div className="d-flex justify-content-between align-items-center order__summary-inner-sec-amount-total">
                    <p>Total</p>
                    <h2>PKR-2700</h2>
                </div>
                <div className="text-center order__summary-inner-sec-amount-btn py-5">
                    <button>Checkout</button>
                </div>
            </div>
        </div>
    );
}
