import React from "react";
import CartButton from "./components/cart_button/CartButton";
import OtpCode from "./components/otp_code_screen/OtpCode";
import Register from "./components/register/Register";
import SendOtp from "./components/send_otp_screen/SendOtp";
import UserAddress from "./components/user_address/UserAddress";
import UserNotification from "./components/user_notfication/UserNotification";
import UserWishlist from "./components/user_wishlist/UserWishlist";
import CategoriesDetails from "./pages/CategoriesDetails";
import CategoryPage from "./pages/CategoryPage";
import CheckOutPage from "./pages/CheckOutPage";
import UserSetting from "./pages/UserSetting";
import HomePage from "./pages/HomePage";
import ViewCartPage from "./pages/ViewCartPage";
import { Route, Routes } from "react-router-dom";
import VendorPage from "./pages/VendorPage";

export default function App() {
    return (
        <>
            <CartButton />
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/vendor-category" element={<VendorPage />} />
                <Route path="/category" element={<CategoryPage />} />
                <Route path="/view-cart" element={<ViewCartPage />} />
                <Route path="/checkout" element={<CheckOutPage />} />
                <Route path="/register" element={<Register />} />
                <Route path="/category-detail" element={<CategoriesDetails />} />
                <Route path="/user-setting" element={<UserSetting />} />
                <Route path="/user-notification" element={<UserNotification />} />
                <Route path="/user-address" element={<UserAddress />} />
                <Route path="/user-whishlist" element={<UserWishlist />} />
            </Routes>
        </>
    );
}
