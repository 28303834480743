import create from "zustand";
import { devtools, persist } from "zustand/middleware";

const cartStore = (set) => ({
    cartArr: [],
    addCart: (cart) => {
        set((state) => ({
            cartArr: [cart, ...state.cartArr],
        }));
    },
    addQuantity: (cartId) => {
        set((state) => ({
            cartArr: state.cartArr.map((cart) =>
                cart.id == cartId
                    ? {
                          ...cart,
                          quantity: cart.quantity + 1,
                      }
                    : { ...cart }
            ),
        }));
    },
    removeQuantity: (cartId) => {
        set((state) => ({
            cartArr: state.cartArr
                .map((cart) => {
                    if (cart.id == cartId) {
                        return {
                            ...cart,
                            quantity: cart.quantity - 1,
                        };
                    } else {
                        return { ...cart };
                    }
                })
                .filter((cart) => cart.quantity >= 0),
        }));
    },
    cartQuantity: (cartId) => {
        set((state) => ({
            cartArr: state.cartArr.map((cart) => {
                if (cart.id == cartId) {
                    return cart.quantity;
                }
            }),
        }));
    },
});

const useStore = create(
    devtools(
        persist(cartStore, {
            name: "My Cart",
        })
    )
);

export default useStore;
