import React from "react";
import "./UserNotification.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Form } from "react-bootstrap";
export default function UserNotification() {
  return (
    <div className="user__notification">
      <div className="user__notification-heading mb-5 my-4">
        <h2>Personal Information</h2>
      </div>
      <div className="user__notification-content">
        <div className="user__notification-content-personal-info">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <h2>Text messages</h2>
              <p>
                Share your profile information to collect the product search
                result
              </p>
            </div>
            <div>
              <Form.Check type="switch" id="custom-switch" />
            </div>
          </div>

          <div className="d-flex justify-content-between align-items-center">
            <div>
              <h2>Text messages</h2>
              <p>
                Share your profile information to collect the product search
                result
              </p>
            </div>
            <div>
              <Form.Check type="switch" id="custom-switch" />
            </div>
          </div>

          <div className="mb-5 mt-3">
            <h3>Account Information</h3>
          </div>

          <div className="d-flex justify-content-between align-items-center">
            <div>
              <h2>Text messages</h2>
              <p>
                Share your profile information to collect the product search
                result
              </p>
            </div>
            <div>
              <Form.Check type="switch" id="custom-switch" />
            </div>
          </div>

          <div className="d-flex justify-content-between align-items-center">
            <div>
              <h2>Text messages</h2>
              <p>
                Share your profile information to collect the product search
                result
              </p>
            </div>
            <div>
              <Form.Check type="switch" id="custom-switch" />
            </div>
          </div>
          <div className="d-flex justify-content-end mt-3 mb-3">
            <button className="btn-save">Save Changes</button>
          </div>
        </div>
      </div>
    </div>
  );
}
