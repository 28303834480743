import React from "react";
import "./Footer.css";
import Footerlogo from "../../assets/images/footer-logo.svg";
import Icon1 from "../../assets/images/footer-icon1.svg";
import Icon2 from "../../assets/images/footer-icon2.svg";
import Icon3 from "../../assets/images/footer-icon3.svg";
import Icon4 from "../../assets/images/footer-icon4.svg";
import Icon5 from "../../assets/images/footer-icon5.svg";
import Icon6 from "../../assets/images/footer-icon6.svg";
import Icon7 from "../../assets/images/footer-icon7.svg";
import Icon8 from "../../assets/images/footer-icon8.svg";
import Icon9 from "../../assets/images/footer-icon9.svg";

import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col } from "react-bootstrap";

export default function Footer() {
    return (
        <div>
            <Container fluid={+true} className="footer-container px-5 py-3">
                <Row className="py-4 mx-sm-0">
                    <Col
                        xs={12}
                        md={4}
                        className="d-flex justify-content-start align-items-center d-flex justify-content-sm-center"
                    >
                        <img src={Icon1} alt="" fluid="true" />
                        <p className="text-white footer-text mb-0">
                            Fresh deliveries straight from the farmers. Always
                            best quality
                        </p>
                    </Col>
                    <Col
                        xs={12}
                        md={4}
                        className="d-flex justify-content-start align-items-center d-flex justify-content-sm-center"
                    >
                        <img src={Icon2} alt="" fluid="true" />
                        <p className="text-white footer-text mb-0 mx-1">
                            Non-contact shipping. We care about your health!
                        </p>
                    </Col>
                    <Col
                        xs={12}
                        md={4}
                        className="d-flex justify-content-start align-items-center d-flex justify-content-sm-center"
                    >
                        <img src={Icon3} alt="" fluid="true" />
                        <p className="text-white footer-text mb-0 mx-1">
                            Free delivery for first order and every next over
                            PKR200
                        </p>
                    </Col>
                    <hr className="text-white mt-3"></hr>
                </Row>
                <Row>
                    <Col className="border-right text-center text-md-start">
                        <img
                            src={Footerlogo}
                            alt=""
                            fluid="true"
                            className="mb-2"
                        />
                        <div className="d-flex  text-white align-items-start address-box p-3 mb-3">
                            <img
                                src={Icon4}
                                alt=""
                                className="d-flex align-items-center"
                                fluid="true"
                            />
                            <p className="mb-0 footer-text-3">
                                Suite#814, 8th Floor، Anum Estate،
                                Shahrah-e-Faisal, Umar Colony,Karachi, Sindh
                                75100
                            </p>
                        </div>
                        <p className="text-white text-left mb-0 footer-text-3">
                            Monday – Friday: 9:00-20:00
                        </p>
                        <p className="text-white text-left footer-text-3">
                            Saturady: 11:00 – 15:00
                        </p>
                    </Col>
                    <Col md={3} className="border-right px-5 pt-3 px-sm-2">
                        <p className="text-white footer-text-2">Need help</p>
                        <div className="d-flex align-items-start justify-content-center justify-content-md-start">
                            <img
                                src={Icon5}
                                alt=""
                                className="d-flex align-items-center"
                                fluid="true"
                            />
                            <p className="text-white"><a href="tel:+92(21)34321774" className="text-white">+92 (21) 34321774</a></p>
                        </div>
                        <p className="text-white footer-text-3">
                            Mon - Fri: 9:00-20:00
                        </p>
                        <hr className="text-white"></hr>
                        <div className="d-flex align-items-center justify-content-center justify-content-md-start">
                            <img src={Icon6} alt="" className="" fluid="true" />
                            <p className="text-white mb-0 mx-2 footer-text-3">
                               <a href="mailto:contact@ingenicotribe.com" className="text-white">contact@ingenicotribe.com</a>
                            </p>
                        </div>
                    </Col>
                    <Col className="px-5">
                        <Row>
                            <Col>
                                <div>
                                    <h2 className="footer-heading">
                                        {" "}
                                        Categories
                                    </h2>
                                    <p className="footer-text">Clothing</p>
                                    <p className="footer-text">
                                        Fragrance and Perfumes{" "}
                                    </p>
                                    <p className="footer-text">Jewelries</p>
                                    <p className="footer-text">Products</p>
                                    <p className="footer-text">Home Decor</p>
                                </div>
                            </Col>
                            <Col>
                                <div>
                                    <p className="footer-text">Clothing</p>
                                    <p className="footer-text">
                                        Fragrance and Perfumes{" "}
                                    </p>
                                    <p className="footer-text">Jewelries</p>
                                    <p className="footer-text">Products</p>
                                    <p className="footer-text">Home Decor</p>
                                </div>
                            </Col>
                            <Col>
                                <div>
                                    <h2 className="footer-heading">Account</h2>
                                    <p className="footer-text">Clothing</p>
                                    <p className="footer-text">
                                        Fragrance and Perfumes{" "}
                                    </p>
                                    <p className="footer-text">Jewelries</p>
                                    <p className="footer-text">Products</p>
                                    <p className="footer-text">Home Decor</p>
                                </div>
                            </Col>
                            <Col>
                                <div>
                                    <h2 className="footer-heading">Store</h2>
                                    <p className="footer-text">Clothing</p>
                                    <p className="footer-text">
                                        Fragrance and Perfumes{" "}
                                    </p>
                                    <p className="footer-text">Jewelries</p>
                                    <p className="footer-text">Products</p>
                                    <p className="footer-text">Home Decor</p>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <hr></hr>
                </Row>
                <Row>
                    <Col xs={12} md={6} className="d-flex">
                        <p className="text-white footer-text-3">About Us</p>
                        <p className="mx-2 text-white footer-text-3">
                            Delivery information
                        </p>
                        <p className="mx-2 text-white footer-text-3">
                            Privacy Policy
                        </p>
                        <p className="mx-2 text-white footer-text-3">Sales</p>
                        <p className="mx-2 text-white footer-text-3">
                            Terms & Conditions
                        </p>
                    </Col>
                    <Col md={6}>
                        <Row>
                            <Col
                                xs={12}
                                md={4}
                                className="d-flex justify-content-center align-items-center justify-content-md-end"
                            >
                                <p className="text-white mb-0 footer-text-3">
                                    Order faster with ourApp!
                                </p>
                            </Col>
                            <Col
                                xs={12}
                                md={4}
                                className="d-flex justify-content-center align-items-center pb-3 pt-2"
                            >
                                <img
                                    src={Icon7}
                                    alt=""
                                    className="mx-2 img-fluid image-icon"
                                />
                                <img
                                    src={Icon8}
                                    alt=""
                                    className="mx-2 img-fluid image-icon"
                                />
                            </Col>
                            <Col
                                xs={12}
                                md={4}
                                className="d-flex justify-content-center align-items-center pb-3"
                            >
                                <img
                                    src={Icon9}
                                    alt=""
                                    className="mx-2 img-fluid image-icon-2"
                                />
                            </Col>
                        </Row>
                    </Col>
                    <p className="mb-0 footer-copyright">
                        Copyright © 2022 Ingenicotribe. All Rights Reserved
                    </p>
                </Row>
            </Container>
        </div>
    );
}
