import React from "react";
import CartButton from "../components/cart_button/CartButton";
import CategoriesDetail from "../components/category_details/CategoriesDetail";
import CustomerDetail from "../components/customer_reviews_details/CustomerDetail";

import Footer from "../components/footer/Footer";
import Header from "../components/header/Header";

export default function CategoriesDetails() {
  const productDetailObj = [
    {
      id: "1",
      title: "V Neck T-Shirt For Men",
      brand: "Polo",
      category: "Clothing, T-shirt",
      sku: "MS46891363",
      price: 2000,
    },
  ];


  return (
    <>
      <Header />
      {productDetailObj.map((productDetail) => {
        return (
          <CategoriesDetail
            key={productDetail.id}
            title={productDetail.title}
            brand={productDetail.brand}
            sku={productDetail.sku}
            price={productDetail.price}
            category={productDetail.category}
          />
        );
      })}
      {/* <CustomerDetail /> */}
      <Footer />
      <CartButton />
      {/* // <VendorInfo /> */}
      {/* // <VisitStore /> */}
      {/* // <MoreProducts /> */}
    </>
  );
}
