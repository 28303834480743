import React from 'react'
import "./MoreProducts.css";
import "bootstrap/dist/css/bootstrap.min.css";
import TShirt1 from "../../assets/images/t-shirt-1.jpg";
import Star from "../../assets/images/product-star.svg";

export default function MoreProducts() {
  return (
    <div className="more__products">
      <div className="d-flex mb-0 more__products-heading">
        <h2 className="mb-0">MORE PRODUCTS</h2>
      </div>
      <hr></hr>
      <div className="more__products-one d-flex justify-content-start">
          <div className="more__products-one-image">
              <img src={TShirt1} alt="tshirt" />
          </div>
          <div className="more__products-one-content">
              <h2>White and black crew-neck</h2>
              <div className="d-flex more__products-one-content-review ">
              <img src={Star} alt="start" />
              <p className="text-muted mb-0">(1 reviews)</p>
              </div>
              <p>PKR 2000<span className="text-muted mx-2">PKR 2500</span></p>
          </div>
      </div>
    </div>
  )
}
