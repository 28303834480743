import React, { useState } from "react";
import "./CartButton.css";
import Logo from "../../assets/images/logo.png";
import "bootstrap/dist/css/bootstrap.min.css";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Button } from "react-bootstrap";
import AddToCartPro from "../add_to_cart_products/AddToCartPro.js";
import useStore from "../../app/store";

export default function CartButton() {
    //Call cartArr from store to fetch products
    const cartArr = useStore((state) => state.cartArr);

    // To get the Total Quantity of all products
    const totalQuantity = cartArr.reduce((sum, cart) => (sum += cart.quantity), 0);

    // To get the Total Price of all products.
    const totalPrice = cartArr.reduce((total, cart) => (total += cart.price * cart.quantity), 0);

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <div className="wrapper">
            <Button variant="primary" onClick={handleShow} className="cart__btn p-2">
                <span className="d-flex justify-content-center align-items-center">
                    <svg width="14" height="16" className="shrink-0" viewBox="0 0 12.686 16">
                        <g transform="translate(-27.023 -2)">
                            <g transform="translate(27.023 5.156)">
                                <g>
                                    <path
                                        d="M65.7,111.043l-.714-9A1.125,1.125,0,0,0,63.871,101H62.459V103.1a.469.469,0,1,1-.937,0V101H57.211V103.1a.469.469,0,1,1-.937,0V101H54.862a1.125,1.125,0,0,0-1.117,1.033l-.715,9.006a2.605,2.605,0,0,0,2.6,2.8H63.1a2.605,2.605,0,0,0,2.6-2.806Zm-4.224-4.585-2.424,2.424a.468.468,0,0,1-.663,0l-1.136-1.136a.469.469,0,0,1,.663-.663l.8.8,2.092-2.092a.469.469,0,1,1,.663.663Z"
                                        transform="translate(-53.023 -101.005)"
                                        fill="currentColor"
                                    ></path>
                                </g>
                            </g>
                            <g transform="translate(30.274 2)">
                                <g>
                                    <path
                                        d="M160.132,0a3.1,3.1,0,0,0-3.093,3.093v.063h.937V3.093a2.155,2.155,0,1,1,4.311,0v.063h.937V3.093A3.1,3.1,0,0,0,160.132,0Z"
                                        transform="translate(-157.039)"
                                        fill="currentColor"
                                    ></path>
                                </g>
                            </g>
                        </g>
                    </svg>
                    <span className="flex"> {totalQuantity} Items</span>
                </span>
                <span className="bg-light rounded w-full py-2 px-2  mt-3 text-dark">PKR {totalPrice}</span>
            </Button>

            <Offcanvas placement={"end"} show={show} onHide={handleClose}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className="text-center">
                        {/* <img src={Logo} alt="" /> */}
                        <h2 className="cart-heading mb-0">SHOPPING CART</h2>
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <hr className="mt-0"></hr>
                <Offcanvas.Body>
                    <AddToCartPro />
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    );
}
