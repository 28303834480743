import React from "react";
import Product from "../product/Product";
import { Row, Col } from "react-bootstrap";
import useStore from "../../app/store";

function ProductList(props) {
    return (
        <>
            <Row>
                <Col lg={12}>
                    <div className={props.heading ? "product__heading" : "d-none"}>
                        <h2>{props.heading}</h2>
                        <p>{props.para}</p>
                    </div>
                </Col>
            </Row>

            <Row>
                {props.products.map((product) => {
                    return <Product key={product.id} title={product.title} img={product.img} price={product.price} tag={product.tag} id={product.id} quantity={product.quantity} />;
                })}
            </Row>
        </>
    );
}

export default ProductList;
