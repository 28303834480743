import React, { useState, useEffect } from "react";
import "./CategoriesDetail.css";
import "bootstrap/dist/css/bootstrap.min.css";
import CatThumb1 from "../../assets/images/categories-thumb-1.png";
import CatThumb2 from "../../assets/images/categories-thumb-2.png";
import CatThumb3 from "../../assets/images/categories-thumb-3.png";
import CatThumb4 from "../../assets/images/categories-thumb-4.png";
import CatThumb5 from "../../assets/images/product-detail-thumb-1.png";
import CatThumb6 from "../../assets/images/product-detail-thumb-2.png";
import CatThumb7 from "../../assets/images/product-detail-thumb-3.png";
import CatThumb8 from "../../assets/images/product-detail-thumb-4.png";
import Star from "../../assets/images/product-star.svg";
import CartIcon from "../../assets/images/cart-icon.svg";
import WhatsappIcon from "../../assets/images/whatsapp-icon.svg";
import { Container, Row, Col, Form } from "react-bootstrap";
import VendorInfo from "../vendor_info/VendorInfo";
import VisitStore from "../visit_store/VisitStore";
import MoreProducts from "../more_products/MoreProducts";
import Offcanvas from "react-bootstrap/Offcanvas";
import useStore from "../../app/store";
// import {
//   MagnifierContainer,
//   MagnifierZoom,
//   MagnifierPreview,
//   SideBySideMagnifier,
// } from "react-image-magnifiers";
// import {GlassMagnifier} from "react-image-magnifiers";
import ReactImageMagnify from "react-image-magnify";
import CustomerDetail from "../customer_reviews_details/CustomerDetail";
import { useNavigate } from "react-router-dom";
// import ReactImageMagnify from 'react-image-magnify/';

export default function CategoriesDetail({ title, brand, sku, price, category }) {
    const navigate = useNavigate();
    const checkoutHandler = (e) => {
        e.preventDefault();
        navigate("/checkout");
    };

    const cartHandler = (e) => {
        e.preventDefault();
        navigate("/view-cart");
    };

    const [counter, setCounter] = useState(1);
    const [addToCard, setAddToCart] = useState(false);
    const [addCartBtn, setAddCartBtn] = useState(true);

    const incrementHandler = () => {
        setAddToCart(true);
        setAddCartBtn(false);
    };

    const countIncrement = () => {
        setCounter(counter + 1);
    };
    const countDecrement = () => {
        setCounter(counter - 1);
        if (counter <= 1) {
            setAddToCart(false);
            setAddCartBtn(true);
            setCounter(1);
        }
    };

    useEffect(() => {}, []);

    return (
        <Container fluid={+true} className="py-5 mt-5">
            <Row>
                <p className="text-muted bread__crumb px-5 mt-3">
                    Home <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    Clothing <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    Men Clouthing White and black crew-neck
                </p>
            </Row>
            <Row>
                <Col lg={9}>
                    <Row>
                        <Col md={2} className="p-0">
                            <div className="catergories__thumb-image d-flex flex-lg-column align-items-center d-flex flex-md-column d-flex flex-xs-row">
                                <img src={CatThumb1} alt="thumb1" className="img-fluid p-2" />
                                <img src={CatThumb3} alt="thumb1" className="img-fluid p-2" />
                                <img src={CatThumb4} alt="thumb1" className="img-fluid p-2" />
                                <img src={CatThumb2} alt="thumb1" className="img-fluid p-2" />
                            </div>
                        </Col>
                        <Col md={4} className="p-0">
                            <div className="catergories__main-image">
                                <ReactImageMagnify
                                    style={{ zIndex: "100" }}
                                    {...{
                                        smallImage: {
                                            alt: "Wristwatch by Ted Baker London",
                                            isFluidWidth: true,
                                            src: CatThumb1,
                                        },
                                        largeImage: {
                                            src: CatThumb1,
                                            width: 1200,
                                            height: 1800,
                                        },
                                    }}
                                />
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="catergories__content">
                                <div className="catergories__content-heading">
                                    <h2>{title}</h2>
                                    <p className="mb-0">
                                        Brand:<span>{brand}</span>
                                    </p>
                                    <p className="mb-0">
                                        Categories:<span>{category}</span>
                                    </p>
                                    <p className="mb-0">
                                        SKU:<span>{sku}</span>
                                    </p>
                                </div>
                                <hr></hr>
                                <div className="catergories__content-heading-two">
                                    <h1>PKR {price}</h1>
                                    <div className="d-flex">
                                        <img src={Star} alt="" />
                                        <p className="text-muted mb-0 px-3">(1 reviews)</p>
                                    </div>
                                </div>
                                <hr></hr>
                                <div>
                                    <div className="d-flex justify-content-start align-items-center ">
                                        <p className="mb-0 text-muted">Color Family</p>
                                        <p className="mx-3 mb-0 text-muted ">Army Green</p>
                                    </div>
                                    <img src={CatThumb5} alt="" className="img-fluid p-2" />
                                    <img src={CatThumb6} alt="" className="img-fluid p-2" />
                                    <img src={CatThumb7} alt="" className="img-fluid p-2" />
                                    <img src={CatThumb8} alt="" className="img-fluid p-2" />
                                </div>
                                <hr></hr>
                                <div>
                                    <div className="d-flex justify-content-start ">
                                        <p className="mb-0 text-muted">Size</p>
                                        <p className="px-5 mb-0 text-muted">Int</p>
                                    </div>
                                    <div className="size-box">
                                        <ul className="selected d-flex justify-content-start mx-5">
                                            <li>
                                                <a href="/">XS</a>
                                            </li>
                                            <li className="active">
                                                <a href="/">S</a>
                                            </li>
                                            <li>
                                                <a href="/">M</a>
                                            </li>
                                            <li>
                                                <a href="/">L</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <hr></hr>
                                <div className="catergories__add-to-cart d-flex justify-content-around align-items-center">
                                    <div className="product__addToCart-button w-50">
                                        {addCartBtn && (
                                            <button onClick={() => incrementHandler("firstAdd")}>
                                                <span className="flex-1 addText fw-bold text-white">
                                                    Add
                                                </span>
                                                <span className="product__cartIcon">
                                                    <svg
                                                        width="12"
                                                        height="18"
                                                        viewBox="0 0 10 10"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M5.12182 5.12891H1.1123M5.12182 1.12891V5.12891V1.12891ZM5.12182 5.12891V9.12891V5.12891ZM5.12182 5.12891H9.13134H5.12182Z"
                                                            stroke="black"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                    </svg>
                                                </span>
                                            </button>
                                        )}

                                        {addToCard && (
                                            <button>
                                                <span
                                                    className="product__cartIcon icon-minus"
                                                    onClick={countDecrement}
                                                >
                                                    <svg
                                                        width="12"
                                                        height="10"
                                                        viewBox="0 0 10 2"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M9.02953 1.05469H5.02001H1.0105"
                                                            stroke="black"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                    </svg>
                                                </span>
                                                <span className="flex-1 addText text-white fw-bold">{counter}</span>
                                                <span
                                                    className="product__cartIcon"
                                                    onClick={countIncrement}
                                                >
                                                    <svg
                                                        width="12"
                                                        height="18"
                                                        viewBox="0 0 10 10"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M5.12182 5.12891H1.1123M5.12182 1.12891V5.12891V1.12891ZM5.12182 5.12891V9.12891V5.12891ZM5.12182 5.12891H9.13134H5.12182Z"
                                                            stroke="black"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                    </svg>
                                                </span>
                                            </button>
                                        )}
                                    </div>
                                    <button
                                        className="text-center py-2 my-2"
                                        style={{ backgroundColor: "#2DB000" }}
                                    >
                                        <img src={WhatsappIcon} alt="" className="" />
                                        Share to Whatsapp
                                    </button>
                                </div>
                                <div
                                    onClick={checkoutHandler}
                                    className="my-4 d-flex justify-content-center"
                                >
                                    <button className="buy__btn">BUY NOW</button>
                                </div>
                            </div>
                        </Col>
                        <Col lg={12}>
                            <CustomerDetail />
                        </Col>
                    </Row>
                </Col>
                <Col lg={3}>
                    <VendorInfo />
                    <VisitStore />
                    <MoreProducts />
                </Col>
            </Row>
        </Container>
    );
}
