import React from "react";
import "./VendorInfo.css";
import "bootstrap/dist/css/bootstrap.min.css";
import VendorImg from "../../assets/images/vendor-img.png";
// import { Container, Row, Col,Form } from "react-bootstrap";

export default function VendorInfo() {
  return (
    <div className="vendor__info">
      <h2>VENDOR INFO</h2>
      <hr></hr>
      <div className="vendor__info-profile d-flex justify-content-start">
        <img src={VendorImg}  alt="vendor" />
        <div className="vendor__info-profile-content d-flex flex-column mx-2">
          <h2 className="mb-0">JANE DOE</h2>
          <p>NO RATINGS FOUND YET!</p>
        </div>
      </div>
      <div className="vendor__info-profile-name d-flex justify-content-start pt-4">
        <p>STORE NAME: </p>
        <p className="mx-4">VENDOR 1</p>
      </div>
      <div className="vendor__info-profile-address d-flex justify-content-start">
        <p>ADDRESS: </p>
        <p className="mx-5">
          PAKISTAN We guarantee our products and you could get back all of your
          money..
        </p>
      </div>
    </div>
  );
}
