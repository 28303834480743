import { React, useState } from "react";
import "./Register.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col, Form } from "react-bootstrap";

export default function Register() {
    const [passwordShown, setPasswordShown] = useState(false);

    const togglePassword = () => {
        setPasswordShown(!passwordShown);
    };
    return (
        <>
        <Container>
            <Row>
                <Col lg={12}>
                    <Row>
                        <Col md={12}>
                            <div className="d-flex justify-content-center align-items-center Register__heading py-3">
                                <p>
                                    By signing up, you agree to <a href="/">ourterms</a> & <a href="/">policy</a>
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Name</Form.Label>
                                <Form.Control type="text" placeholder="Name" />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Email</Form.Label>
                                <Form.Control type="email" placeholder="Email" />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Label>Password</Form.Label>
                                <Form.Control type={passwordShown ? "text" : "password"} placeholder="Password" className="relative" />
                                <button type="button" onClick={togglePassword} className="register-hide-btn text-center">
                                    <i className="fa fa-eye" aria-hidden="true"></i>
                                </button>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <button className="btn-Register">Register</button>
                        </Col>
                    </Row>
                    <Row>
                        <div className="register__divider">
                            <hr></hr>
                            <span className="register__divider-inner">Or</span>
                        </div>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <div className="d-flex justify-content-center register__footer">
                                <p className="mb-0">Already have an account? </p>
                                <a href="/">Login</a>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
        </>
    );
}
