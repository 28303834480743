import React from 'react'
import "bootstrap/dist/css/bootstrap.min.css";
import { Container,Row,Col } from "react-bootstrap";
import VendorBanner from '../components/vendor_catergories_banner/VendorBanner';
import CartButton from '../components/cart_button/CartButton';
import VendorProfile from '../components/vendor_profile/VendorProfile';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import ProductList from '../components/productList/ProductList';
import product1 from "../assets/images/product1.png";
import product2 from "../assets/images/product2.png";
import product3 from "../assets/images/product3.png";
import product4 from "../assets/images/product4.png";
import product5 from "../assets/images/product5.png";
import product6 from "../assets/images/product6.png";
import product7 from "../assets/images/product7.png";
import product8 from "../assets/images/product8.png";
import product9 from "../assets/images/product9.png";
import product10 from "../assets/images/product10.png";
import product11 from "../assets/images/product11.png";
import product12 from "../assets/images/product12.png";
export default function VendorPage() {
    const productData = [
        {
            id: "1",
            img: product1,
            title: "White and black crew-neck",
            price: 400,
            quantity: 1,
            tag: {
                value: 17,
                bg: "#02B290",
            },
        },
        {
            id: "2",
            img: product2,
            title: "White and black crew-neck",
            price: 400,
            quantity: 1,
            tag: {
                value: 29,
                bg: "#A4000D",
            },
        },
        {
            id: "3",
            img: product3,
            title: "White and black crew-neck",
            price: 400,
            quantity: 1,
            tag: {
                value: 50,
                bg: "#A4000D",
            },
        },
        {
            id: "4",
            img: product4,
            title: "White and black crew-neck",
            price: 400,
            quantity: 1,
            tag: {},
        },
        {
            id: "5",
            img: product5,
            title: "White and black crew-neck",
            price: 400,
            quantity: 1,
            tag: {
                value: 10,
                bg: "#A4000D",
            },
        },
        {
            id: "6",
            img: product6,
            title: "White and black crew-neck",
            price: 400,
            quantity: 1,
            tag: {
                value: 17,
                bg: "#02B290",
            },
        },
        {
            id: "7",
            img: product7,
            title: "White and black crew-neck",
            price: 400,
            quantity: 1,
            tag: {},
        },
        {
            id: "8",
            img: product8,
            title: "White and black crew-neck",
            price: 400,
            quantity: 1,
            tag: {
                value: 17,
                bg: "#A4000D",
            },
        },
        {
            id: "9",
            img: product9,
            title: "White and black crew-neck",
            price: 400,
            quantity: 1,
            tag: {
                value: 17,
                bg: "#A4000D",
            },
        },
        {
            id: "10",
            img: product10,
            title: "White and black crew-neck",
            price: 400,
            quantity: 1,
            tag: {
                value: 17,
                bg: "#A4000D",
            },
        },
        {
            id: "11",
            img: product11,
            title: "White and black crew-neck",
            price: 400,
            quantity: 1,
            tag: {
                value: 17,
                bg: "#A4000D",
            },
        },
        {
            id: "12",
            img: product12,
            title: "White and black crew-neck",
            price: 400,
            quantity: 1,
            tag: {
                value: 17,
                bg: "#A4000D",
            },
        },
    ];
  return (
    <>
    <Header />
    <Container fluid={+true} className="pt-5 mt-5">
        <Row>
            <Col lg={3}>
                <VendorProfile />
            </Col>
            <Col lg={9}>
                <VendorBanner />
                <ProductList  products={productData}/>
            <CartButton />
            </Col>
        </Row>
    </Container>
    <Footer />
    </>
  )
}
