import React from "react";
import "./VisitStore.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Arrow from "../../assets/images/Arrow-right.svg";
import Shipping from "../../assets/images/shipping.svg";
import MoneyBack from "../../assets/images/money-back-icon.svg";
import SecureIcon from "../../assets/images/secure-icon.svg";
import { useNavigate } from "react-router-dom";

export default function VisitStore() {
  const navigate = useNavigate();
  const vendorHanlder = (e) => {
      e.preventDefault();
      navigate("/vendor-category");
  };
  return (
    <div className="visit__store">
      <div className="d-flex mb-0 visit__store-heading">
        <h2 className="mb-0" onClick={vendorHanlder}>VISIT STORE</h2>
        <img src={Arrow} alt="arrow" className="mx-2" />
      </div>
      <hr></hr>
      <div className="visit__store-detail my-5">
        <div className="visit__store-detail-shipping d-flex justify-content-start align-items-center">
          <img src={Shipping} alt="arrow" />
          <div className="visit__store-detail-shipping-content mx-2">
            <h2 className="mb-0">Free Shipping & Returns</h2>
            <p className="mb-0">For all orders over $99</p>
          </div>
        </div>
        <hr></hr>
        <div className="visit__store-detail-shipping d-flex justify-content-start align-items-center">
          <img src={SecureIcon} alt="arrow" />
          <div className="visit__store-detail-shipping-content mx-2 py-2">
            <h2 className="mb-0">Free Shipping & Returns</h2>
            <p className="mb-0">For all orders over $99</p>
          </div>
        </div>
        <hr></hr>
        <div className="visit__store-detail-shipping d-flex justify-content-start align-items-center">
          <img src={MoneyBack} alt="arrow" />
          <div className="visit__store-detail-shipping-content mx-2 py-2">
            <h2 className="mb-0">Free Shipping & Returns</h2>
            <p className="mb-0">For all orders over $99</p>
          </div>
        </div>
      </div>
    </div>
  );
}
