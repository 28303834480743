import React from "react";
import "./MobileApp.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col } from "react-bootstrap";
import AppImage from "../../assets/images/mobile-store.png";

export default function MobileApp() {
    return (
        <div>
            <Container fluid={+true} className="mobile__app">
                <Row>
                    <Col
                        xs={12}
                        md={6}
                        className="d-flex flex-column justify-content-center align-items-start px-5 my-4"
                    >
                        <h1 className="mobile__app_heading ">
                            Make your online shop easier<br></br> with our
                            mobile app
                        </h1>
                        <p className="mobile__app_para">
                            Ingenicotribe makes online shopping fast and easy.
                            Get shopping<br></br>
                            delivered and order..
                        </p>
                    </Col>
                    <Col
                        xs={12}
                        md={6}
                        className="d-flex justify-content-md-center d-flex justify-content-sm-center"
                    >
                        <img src={AppImage} alt="" className="img-fluid" />
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
