import React from 'react'
import "./VendorBanner.css";

export default function VendorBanner() {
  return (
    <div className="vendor__catergory-banner mb-4">

    </div>
  )
}
