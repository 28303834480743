import React from "react";
import "./CustomerDetail.css";
import "bootstrap/dist/css/bootstrap.min.css";
// import Star from "../../assets/images/product-star.svg";
import CustomerDetPic from "../../assets/images/customer-detail-pic.png";
import { Container, Row, Col } from "react-bootstrap";
// import Nav from "react-bootstrap/Nav";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

export default function CustomerDetail(props) {
  return (
    // <Container fluid={+true}>
      // <Row>
        // <Col lg={9}>
          <Tabs
            defaultActiveKey="Detail"
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            <hr></hr>
            <Tab eventKey="Detail" className="detail__Reviews" title="Detail">
              <div className="details__customer d-flex flex-lg-row flex-sm-column flex-xs-column flex-md-row">
                <div className="details__customer-content">
              <h2>Material</h2>
              <p>MATOA Way Kambas - Sumatran Rhino comes with a material form of Makassar Ebony (Diospyros celebica). This wood is chosen to represent the Sumatran Rhino's skin which is designed with an overlap effect on its strap to represent Rhino's skin. Sumatran Rhino has unique skin fold, the skin is fairly thin about 10-16mm, and is soft and pliable.</p>
              <h2>Case</h2>
              <p>The case on this timepiece is made with Height 12.5mm, Width 36mm, Length 33mm and Dial Opening 25mm. The glass is equipped with K1 flat glass to increase the enhanced durability on timepieces as scratch resistant and anti fingerprint. This timepiece is also equipped with Hand Polish Brass Spacer for a longer usage periods.</p>
              <h2>Movement</h2>
              <p>For the first time, MATOA comes with Seiko Quartz VD78 movement with small second function. A small second hand is above 6 o'clock position. This second hand dial is dedicated to Sumatran Rhino whom every second of its life is so precious because whenever Sumatran Rhino goes, it can be threatened by illegal poaching</p>
              </div>
              <div className="details__customer-content-image">
                <img src={CustomerDetPic} alt="" className="img-fluid" />
              </div>
              </div>
            </Tab>
            <Tab eventKey="profile" title="Customer Reviews (0)">
            <div className="details__customer-content">
              <h2>Material</h2>
              <p>MATOA Way Kambas - Sumatran Rhino comes with a material form of Makassar Ebony (Diospyros celebica). This wood is chosen to represent the Sumatran Rhino's skin which is designed with an overlap effect on its strap to represent Rhino's skin. Sumatran Rhino has unique skin fold, the skin is fairly thin about 10-16mm, and is soft and pliable.</p>
              <h2>Case</h2>
              <p>The case on this timepiece is made with Height 12.5mm, Width 36mm, Length 33mm and Dial Opening 25mm. The glass is equipped with K1 flat glass to increase the enhanced durability on timepieces as scratch resistant and anti fingerprint. This timepiece is also equipped with Hand Polish Brass Spacer for a longer usage periods.</p>
              <h2>Movement</h2>
              <p>For the first time, MATOA comes with Seiko Quartz VD78 movement with small second function. A small second hand is above 6 o'clock position. This second hand dial is dedicated to Sumatran Rhino whom every second of its life is so precious because whenever Sumatran Rhino goes, it can be threatened by illegal poaching</p>
              </div>
            </Tab>
            <Tab eventKey="contact" title="Questions and Answers" >
            <div className="details__customer-content">
              <h2>Material</h2>
              <p>MATOA Way Kambas - Sumatran Rhino comes with a material form of Makassar Ebony (Diospyros celebica). This wood is chosen to represent the Sumatran Rhino's skin which is designed with an overlap effect on its strap to represent Rhino's skin. Sumatran Rhino has unique skin fold, the skin is fairly thin about 10-16mm, and is soft and pliable.</p>
              <h2>Case</h2>
              <p>The case on this timepiece is made with Height 12.5mm, Width 36mm, Length 33mm and Dial Opening 25mm. The glass is equipped with K1 flat glass to increase the enhanced durability on timepieces as scratch resistant and anti fingerprint. This timepiece is also equipped with Hand Polish Brass Spacer for a longer usage periods.</p>
              <h2>Movement</h2>
              <p>For the first time, MATOA comes with Seiko Quartz VD78 movement with small second function. A small second hand is above 6 o'clock position. This second hand dial is dedicated to Sumatran Rhino whom every second of its life is so precious because whenever Sumatran Rhino goes, it can be threatened by illegal poaching</p>
              </div>
            </Tab>
          </Tabs>
    //     </Col>
    //   </Row>
    // </Container>
  );
}
