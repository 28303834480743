import React from "react";
import "./CategoriesCard.css";
import "bootstrap/dist/css/bootstrap.min.css";
// import { Container, Row, Col, ButtonGroup } from "react-bootstrap";
// import CatImage1 from "../../assets/images/category-card1.png";
export default function CategoriesCard({ title, desc, image, bg }) {
    return (
        <div
            className="categories__home_card m-2 d-flex justify-content-center d-flex flex-md-column d-flex flex-xl-row"
            style={{ background: bg }}
        >
            <img src={image} height={110} alt="category" />
            <div className="categories__home_card_content pt-3">
                <h2>{title}</h2>
                <p>{desc}</p>
            </div>
        </div>
    );
}
