import React from "react";
import "./LegalNotice.css";
import "bootstrap/dist/css/bootstrap.min.css";

export default function LegalNotice() {
  return (
    <div className="legal__notice">
      <div className="legal__notice-heading mb-3 my-4">
        <h2>Legal Notice</h2>
      </div>
      <div className="legal__notice-content mt-5">
        <div>
          <h2>Purpose</h2>
          <p>
            The following outlines the terms of use for all BoroBazar template,
            services and website. Before you sign up and download or use RedQ
            downloadable product for your own purposes, please make sure you
            have read, understood, and agreed to all the terms.By using RedQ
            and/or other products, we assume you've accepted the terms of use
            given below.
          </p>
        </div>
        <div>
          <h2>Amendments</h2>
          <p>
            RedQ Team. BoroBazar reserves the right, at its sole discretion, to
            modify or replace the terms of this Agreement at any time. If the
            alterations constitute a material change, RedQ may or may not notify
            you by posting an announcement on this website. What constitutes a
            material change will be determined at RedQ's sole discretion. You
            shall be responsible for reviewing and becoming familiar with any
            such modifications. Using our Services following notification of a
            material change to this Agreement shall constitute your acceptance
            of the Agreement as modified.
          </p>
        </div>
        <div>
          <h2>Amendments</h2>
          <p>
            RedQ Team. BoroBazar reserves the right, at its sole discretion, to
            modify or replace the terms of this Agreement at any time. If the
            alterations constitute a material change, RedQ may or may not notify
            you by posting an announcement on this website. What constitutes a
            material change will be determined at RedQ's sole discretion. You
            shall be responsible for reviewing and becoming familiar with any
            such modifications. Using our Services following notification of a
            material change to this Agreement shall constitute your acceptance
            of the Agreement as modified.
          </p>
        </div>
      </div>
    </div>
  );
}
